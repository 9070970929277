<template>
  <div>
    <user-list-top-nav />
    <div>
      <v-data-table
        :items="blockedUsersList"
        :pagination.sync="pagination"
        :loading="isLoading" 
        :total-items="total"
        :rows-per-page-items="[10, 25, 50, 100]"
        class="elevation-1"
        :headers-length="2"
      >
        <template slot="items" slot-scope="props">
          <td>
            <router-link
              :to="`/moderation/user_list/user/${props.item.id}`"
              class="blue-grey--text bold link-hover"
            >
              {{ props.item.username }}
            </router-link>
            <span v-if="props.item.isNew" class="red--text ml-3 bold">New!</span>
            <span
              v-if="props.item.selected"
              class="ml-3 green--text font-size-12"
            >
              has been unblocked
            </span>
          </td>
          <td
            v-if="getUserInfoAndPermissions.role === 'Admin'"
            class="text-xs-right btn-block"
          >
            <v-btn
              v-if="!props.item.selected"
              :loading="props.item.isBlockLoading"
              block
              color="success"
              class="ma-0"
              @click="unlockedUser(props.item)"
            >
              Unblock User
            </v-btn>

            <v-btn
              v-else
              :loading="props.item.isBlockLoading"
              block
              color="warning"
              class="ma-0"
              @click="blockUser(props.item)"
            >
              Cancel
            </v-btn>
          </td>
        </template>
      </v-data-table>

      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          @input="refreshPagination"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { log } from "../../../../../utils/helpers"
import UserListTopNav from "../UserListTopNav"
import SavesPagination from "../../../../../mixins/SavesPaginationMixin"
import { fetchData } from "../../../../../utils/response"
import { getBlockedUsersRequest } from "../../../../../api/users"
import { userUnbanned, userBanned } from "../../../../../api/user"

export default {
  name: "BlockedUsersList",
  components: {
    UserListTopNav
  },
  mixins: [SavesPagination],
  data() {
    return {
      pagination: {},
      blockedUsersList: [],
      isLoading: false,
      total: 0,
      select: null,
      search: null
    }
  },
  computed: {
    ...mapGetters(["getUserInfoAndPermissions"]),
    pages() {
      if (this.pagination.rowsPerPage == null || this.total == null) return 0

      return Math.ceil(this.total / this.pagination.rowsPerPage)
    }
  },
  watch: {
    /**
     * Watch pagination changes and load more data
     */
    pagination(pagination) {
      this.$paginationCacheSave(pagination.page, pagination.rowsPerPage)
      this.refresh()
    }
  },
  mounted() {
    const pagination = this.$paginationCacheGet()
    this.pagination.page = pagination.page
    this.pagination.rowsPerPage = pagination.per_page
  },
  created() {
    this.$bus.on("user-sort-by", this.onSelectUsersBy)
    this.$bus.on("user-search-input", this.onSearchUser)
  },
  destroyed() {
    this.search = null
    this.$bus.off("user-sort-by", this.onSelectUsersBy)
    this.$bus.off("user-search-input", this.onSearchUser)
  },
  methods: {
    async refresh() {
      const params = {
        page: this.pagination.page,
        per_page: this.pagination.rowsPerPage,
        order_by: this.select,
        search: this.search
      }
      this.isLoading = true
      // log(params, "paramsparamsparams");
      this.blockedUsersList = await getBlockedUsersRequest(params)
        .then(response => {
          this.total = response.data.meta.total
          return response
        })
        .then(fetchData)
        .then(data =>
          data.map(user => {
            return user
          })
        )
        .catch(this.$notificationHandleResponseError)

      this.isLoading = false
    },
    blockUser(item) {
      this.$set(item, "isBlockLoading", true)
      log(item)
      log(item.id, item.banned.reason_id, item.banned.reason_description)
      userBanned(item.id, item.banned.reason_id, item.banned.reason_description)
        .then(() => {
          this.$set(item, "selected", !item.selected)
        })
        .catch(this.$notificationHandleResponseError)
        .finally(() => this.$set(item, "isBlockLoading", false))
    },
    unlockedUser(item) {
      this.$set(item, "isBlockLoading", true)
      log(item)
      userUnbanned(item.id)
        .then(() => {
          this.$set(item, "selected", !item.selected)
          this.$notificationShow("The user is successfully unlocked")
        })
        .catch(this.$notificationHandleResponseError)
        .finally(() => this.$set(item, "isBlockLoading", false))
    },
    refreshPagination() {
      this.refresh()
      this.$paginationCacheSave(
        this.pagination.page,
        this.pagination.rowsPerPage
      )
    },
    onSelectUsersBy(sortUserSelectValue) {
      this.select = sortUserSelectValue
      this.refresh()
    },
    onSearchUser(searchUserValue) {
      this.search = searchUserValue
      this.refresh()
    }
  },
}
</script>

<style scoped>
.link-hover:hover {
  color: #fff !important;
  text-decoration: none !important;
}

.btn-block {
  width: 190px;
}
</style>
